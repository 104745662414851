import {useState,useRef} from 'react'
import Grid from 'react-easy-css-grid-layout'
import {useMatchMedia} from 'react-hook-match-media'
import axios from 'axios'
import { useSpring, animated,easings } from '@react-spring/web'

// hook to check if an element is in the viewport
import useIsVisible from './hooks/useIsVisible.js'


import Showcase from './components/Showcase.js'
import Bottom from './components/Bottom.js'
import HowWeWork from './components/HowWeWork.js'
import WhatWeDo from './components/WhatWeDo.js'

import './v1.css';
import {title} from './data/content.js'; 


function V1() {

  const [version,setVersion]=useState(1)

  const [midSection, setMidSection] = useState('whatWeDo')

// hook to check if an element is in the viewport
  const ref = useRef();
  const midSectionVisible = useIsVisible(ref);

  const whatWeDoRef = useRef();
  const whatWeDoRefisVisible = useIsVisible(whatWeDoRef);

  const sliderRef = useRef();
  const sliderisVisible = useIsVisible(sliderRef);


  // const howWeWorkRef = useRef();
  // const howWeWorkRefisVisible = useIsVisible(howWeWorkRef);
  // // end

  let columns = '2'
  const isSmallScreen = useMatchMedia('(max-width: 700px)');
  columns = isSmallScreen ? '1':'2'

  const opacityRender = useSpring({
    from: { opacity: 0 },
    to: { opacity: whatWeDoRefisVisible?1:0 },
    config: { duration: 1000 /*mass: 1, tension: 80, friction: 5*/ } // Adjust these values for the desired spring effect
  });

    const opacityRenderSlider = useSpring({
    from: { opacity: 0 },
    to: { opacity: sliderisVisible?1:0 },
    config: { duration: 1000 /*mass: 1, tension: 80, friction: 5*/ } // Adjust these values for the desired spring effect
  });

  const hwwSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { tension: 280, friction: 20 }, // Adjust these values for the desired spring effect
  });


  const springs = useSpring({
    from: { x: -3500 },
    to: { x: 0 },
    config: { tension: 200, friction: 20 }, // Adjust these values for the desired bounce effect
    onRest: () => {
      // Animation is complete, you can trigger another animation here if needed
    },
  })



  const titleAnimation = useSpring({
    from: { x: -3500 },
    to: { x: 0 },
    delay:200,
    config: { tension: 200, friction: 20 },
  })

  const heroImageAnimation = useSpring({
    from: { x: 3500 },
    to: { x: 0 },
    delay:400,
    config: { tension: 200, friction: 20 },
  })

  const slideFromLeftAnimation = useSpring({
    // delay:300,
    from: { x: -3500 },
    to: { x: midSectionVisible ? 0 : -3500 },
    config: { tension: 200, friction: 20 },
  })

  const slideFromRightAnimation = useSpring({
    // delay:300,
    from: { x: 3500 },
    to: { x: midSectionVisible ? 0 : 3500 },
    config: { tension: 200, friction: 20 },
  })

  return (
<>
    <section>  
    <animated.div style={{...springs}}>  
    <h1>{title}</h1>
    </animated.div>
    <hr style={{marginTop:0}}/>
    <Grid columns={columns} gap={'1em'}>
    {/*<div>*/}
    <animated.div style={{...titleAnimation}}>
    <h2>Bringing your ideas to life</h2>
    <p>Design and development of digital products and services</p>
    <a href='mailto:office@barcelonacodeschool.com'><button className='green'>Email us</button></a>
    </animated.div>
    {/*</div>*/}
    <animated.div style={{...heroImageAnimation}}>
    <img src='./Undefeated Software-logos/Undefeated Software-logos.jpeg' alt='Undefeated Software Logo'/>
    </animated.div>

    </Grid>
    </section>

    <hr ref={ref}/>
    <div className='midSectionNav' >
    <animated.div style={{...slideFromLeftAnimation}}> 
    <h2 onClick={()=>setMidSection('whatWeDo')}>What we do</h2>
    </animated.div> 
    <animated.div style={{...slideFromRightAnimation}}>
    <h2 onClick={()=>setMidSection('howWeWork')}>How we work</h2>
    </animated.div>
    </div>

    {/* midsection conditional content*/}
    <div ref={whatWeDoRef}>
    {
      midSection === 'whatWeDo' ? (
        <animated.div style={opacityRender}>
        <WhatWeDo  />
        </animated.div>
        ) : (
        <animated.div style={opacityRender}>
        <HowWeWork />
        </animated.div>
        )
      }
      </div>
      <hr/>

      <section className='centered' ref={sliderRef}>
      <animated.div style={opacityRenderSlider}>
      <Showcase dots={true} arrows={true}/>
      </animated.div>
      </section>

      <hr/>
      <Bottom/>
      </>
      );
}

export default V1;
