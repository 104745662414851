import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Showcase = ({dots,arrows, sliderWidth}) => {
  const settings = {
    dots,
    arrows,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const styles={
    images: {
      width: sliderWidth || '80%',
      display: 'block',
      margin: '0 auto 1em',
    },
    slider: {
      width:sliderWidth || '90%',
      margin:'0 auto'
    }
  }

  return (
    <div>
    <h2>Some products we've created</h2>
    <Slider {...settings} style={styles.slider}>

    <article>
    <h3><a href='https://toolboxai.app' target ='_blank'>toolboxAI.app</a></h3>
    <p>Set of AI tools on demand</p>
    <a href='https://toolboxai.app' target ='_blank'><img style={styles.images} src="images/toolboxAI.jpg" alt="toolboxAI.app - set of AI tools on demand" /></a>
    </article>

    <article>
    <h3><a href='http://bsides.barcelona' target ='_blank'>BSides.barcelona</a></h3>
    <p>CyberSecurity forum's website</p>
    <a href='http://bsides.barcelona' target ='_blank'><img style={styles.images} src="images/bsides-barcelona.jpg" alt="BSides.barcelona – CyberSecurity forum's website" /></a>
    </article>

    <article>
    <h3><a href='https://goodbadplace.com' target ='_blank'>Good Bad Place</a></h3>
    <p>Rate your landlord</p>
    <a href='https://goodbadplace.com' target ='_blank'><img style={styles.images} src="images/goodbadplace.jpg" alt="Good Bad Place - landlords and rentals reviews platform" /></a>
    </article>

    <article>
    <h3><a href='https://barcelonacodeschool.com/' target ='_blank'>Barcelona Code School</a></h3>
    <p>Programming school's website</p>
    <a href='https://barcelonacodeschool.com/' target ='_blank'><img style={styles.images} src="images/bcs.jpg" alt="Barcelona Code School" /></a>
    </article>


    <article>
    <h3><a href='https://bxlcybersummit.be' target ='_blank'>Brussels CyberSecurity Summit</a></h3>
    <p>Participants landing page</p>
    <a href='https://bxlcybersummit.be' target ='_blank'><img style={styles.images} src="images/ccb.jpg" alt="Brussels CyberSecurity Summit" /></a>
    </article>


    <article>
    <h3><a href='https://apps.apple.com/us/app/weather-os/id6443399556' target ='_blank'>Weather OS</a></h3>
    <p>iOS weather app</p>
    <a href='https://apps.apple.com/us/app/weather-os/id6443399556' target ='_blank'><img style={styles.images} src="images/weatherOS.jpg" alt="Weather OS - a terminal themed weather app" /></a>
    </article>


    <article>
    <h3><a href='https://apps.apple.com/us/app/picky-eater-go/id6451353816?platform=iphone' target ='_blank'>Picky Eater Go!</a></h3>
    <p>iOS/Android arcade game</p>
    <a href='https://apps.apple.com/us/app/picky-eater-go/id6451353816?platform=iphone' target ='_blank'><img style={styles.images} src="images/pickyEaterGo.jpg" alt="Picky Eater Go! - iOS arcade game" /></a>
    </article>


    <article>
    <h3><a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target ='_blank'>Guess a Word or Die</a></h3>
    <p>iOS/Android word game</p>
    <a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target ='_blank'><img style={styles.images} src="images/guessaword.jpg" alt="Guess a Word or Die - iOS word game" /></a>
    </article>


    <article>
    <h3><a href='https://apps.apple.com/us/app/i-have-i-need/id1621110781' target ='_blank'>I Have, I Need</a></h3>
    <p>iOS/Android Inventory and recurring shopping list</p>
    <a href='https://apps.apple.com/us/app/i-have-i-need/id1621110781' target ='_blank'><img style={styles.images} src="images/ihaveineed.jpg" alt="I Have, I Need - Inventory and recurring shopping list" /></a>
    </article>

    <article>
    <h3><a href='https://apps.apple.com/us/app/anime-calculator/id1567378874?platform=iphone' target ='_blank'>Anime Calculator</a></h3>
    <p>Anime themed calculator app for iOS and Android</p>
    <a href='https://apps.apple.com/us/app/anime-calculator/id1567378874?platform=iphone' target ='_blank'><img style={styles.images} src="images/animecalc.jpg" alt="Anime themed calculator app for iOS and Android" /></a>
    </article>



    </Slider>
    </div>
    );
};

export default Showcase;
