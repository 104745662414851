import React from 'react'
import { CardBody, CardContainer, CardItem } from './3d-card.tsx'

export default function CardWrapper({url,title,desc,imgUrl,imgAlt}) {
	return (
		<article className='threeD'>
		<h3><a href={url} target ='_blank'>{title}</a></h3>
		<p>{desc}</p>

		<CardContainer className="inter-var">
		<CardBody className="bg-gray-50 relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border  ">
		
		<CardItem
		translateZ="100"
		rotateX={0}
		rotateZ={-0}
		className="w-full mt-4"
		// translateX="100"
		// translateY="-20"
		>
		

		<a href={url} target ='_blank'>
		<img
		src={imgUrl}
		alt={imgAlt}
		width="100%"
		className="h-60 w-full object-cover rounded-xl group-hover/card:shadow-xl"
		alt="thumbnail"
		/>
		</a>
		</CardItem>
		</CardBody>
		</CardContainer>
		</article>
		)
}