import {useState,useRef} from 'react'
import Grid from 'react-easy-css-grid-layout'
import {useMatchMedia} from 'react-hook-match-media'
import axios from 'axios'
import { useSpring, animated,easings } from '@react-spring/web'
// hook to check if an element is in the viewport
import useIsVisible from '../hooks/useIsVisible.js'

export default function Bottom() {
  // animations
  const ref = useRef();
  const ctaIsVisible = useIsVisible(ref);
  const slideFromLeftAnimation = useSpring({
    // delay:300,
    from: { x: -3500 },
    to: { x: ctaIsVisible ? 0 : -3500 },
    config: { tension: 200, friction: 20 },
  })

  const slideFromRightAnimation = useSpring({
    // delay:300,
    from: { x: 3500 },
    to: { x: ctaIsVisible ? 0 : 3500 },
    config: { tension: 200, friction: 20 },
  })
// end


  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-856512071/VGgbCMOAoIkZEMestZgD',
      'event_callback': callback
    });
    return false;
  }


  const [form,setForm]=useState({name:'',email:'',message:''})
  console.log(gtag_report_conversion)
  const formSubmit=async(e)=>{
    e.preventDefault()
    gtag_report_conversion()

    try {
      const sendMsg = await axios.post('https://barcelonacodeschool.com/sendemail', {name: form.name, email: form.email, message: form.message, subject: 'Inquiry from Undefeated Software Website'})
      // const docRef = await addDoc(collection(db, "messages"), {
      //   name: form.name,
      //   email: form.email,
      //   message: form.message
      // });
      console.log("Form send:", sendMsg);
      alert('Your message hes been send! We will get back to you as soon as possible.')
      setForm({name:'',email:'',message:''})
    } catch (e) {
      console.error("Error adding document: ", e);
    }

  }


  let columns = '2'
  const isSmallScreen = useMatchMedia('(max-width: 700px)');
  columns = isSmallScreen ? '1':'2'


  return (
    <div id='bottom' ref={ref}>
    <section className='centered'>
    <animated.div style={{...slideFromLeftAnimation}}> 
    <h3 className='ctaText'>Ready to see your product built?</h3>
    </animated.div>  
    <animated.div style={{...slideFromRightAnimation}}>
    <a href='https://zcal.co/i/Dq_95JR9' target='_blank' rel="noreferrer"><button className='green'>Schedule a call</button></a>
    </animated.div>
    </section>

    <hr/>



    <section>
    <Grid columns={columns} gap={'1em'}>
    <iframe title='Our location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.166982897201!2d2.14822407614774!3d41.39217987129918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a29991f50309%3A0xa6b60c3379db2652!2sBarcelona%20Code%20School!5e0!3m2!1sen!2ses!4v1704473043809!5m2!1sen!2ses" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    <article>
    <h3>Contact us</h3>
    <form onSubmit={formSubmit} name="contactForm">
    <input value={form.name} onChange={(e)=>setForm({...form,name:e.target.value})} type="text" placeholder="Name" />
    <input value={form.email} onChange={(e)=>setForm({...form,email:e.target.value})} type="email" placeholder="Email" />
    <textarea value={form.message} onChange={(e)=>setForm({...form,message:e.target.value})} type="text" placeholder="Message" />
    <button className='green'>Send</button>

    </form>
    </article>
    </Grid>
    </section>

    <hr/>

    <footer className='centered'>
    <p><a href='https://undefeatedsoftware.dev' >Undefeated Software</a> / <a href='https://barcelonacodeschool.com/' target='_blank'>Barcelona Code School</a> © {(new Date()).getFullYear()}</p>
    <address>c/ Paris 157, 08036, Barcelona, Spain</address>
    </footer>
    </div>
    )
}