import {useState} from 'react'
import Grid from 'react-easy-css-grid-layout'
import {useMatchMedia} from 'react-hook-match-media'
import axios from 'axios'


const rArrow = 'images/icons/_i_icon_05984_icon_059842.svg'

export default function HowWeWork() {

   

  let columns = '1'


  return (


    <section>
    <Grid columns={columns} gap={'1em'}>

    <div>
    <article className='uxuicontainer'>
    <img className='icon' src={rArrow}/><h3 className='servicesHeading'>Meeting you</h3>
    <p>First you will have a thorough conversation with your Project Manager and the User Experience Designer. We will listen to you and ask a lot of questions to understand your product.</p> 
    <p> We will identify all the features you need, understand your target audience, the problem you are trying to solve and find out the visual references for the esthetics of your product.</p>
    </article>

    <article>
    <img className='icon' src={rArrow}/><h3 className='servicesHeading'>UX and UI Design</h3>
    <p>We will start working on the architecture and structure of your project. Conducting full UX research with discovery, definition, infomation architecture, sketching, testing, wireframing, prototyping and usability testing.</p>
    <p>We will test several styleguides and pick the one which was perceived as the best.</p>
    </article>
    </div>

    <article>
    <img className='icon' src={rArrow}/><h3 className='servicesHeading'>Development phase</h3>
    <p>Once the architecture of your project and the visual styles are ready and confirmed with you our software engineers will start working on it ro bring it to live.</p>
    <p>With the most suitable and modern technologies we will see through the continious integration and delivery in phases starting from the core functionality so you can begin testing it and rolling out the full version in the shortest time possible.</p>
    <p>We can take care of deployment and publishing if needed completing the cycle to the moment of your project being live on web or AppStore/Google Play.</p>
    </article>

    </Grid>
    </section>
    )
}