import React, {useState} from 'react'
import TextTyper from 'text-type-animation-effect-react'

import './v2.css'

import {title} from './data/content.js'; 


import ContactForm from './components/Form.js' 


export default function V2() {


const [loadSequens, setLoadSequense]=useState(1)

	const styles={
		images: {
			width: '100%',
		},
	}


	return (
		<>
		<main className='grid'>
		<div>
		<h1>
		<TextTyper text={title.toUpperCase()} interval={50} Markup={"code"} />
		</h1>

		<h2>Bringing your ideas to life</h2>

		<p>Design and development of digital products and services</p>
		</div>

		<a href='mailto:office@barcelonacodeschool.com' className='grid__child__spanTillRightEnd grid__child__vert_center'>
		<button>Email us</button>
		</a>

		<h2>What we do</h2>

		<article>
		<h3>User Experience Design</h3>
		<p>Before starting the development we can conduct the full process of User Experience (UX) and User Interface (UI) Design.</p>
		<p>From the problem you are trying to solve we can validate it, conduct the user research to understand that your idea will meet the users' needs, design the structure and architecture of your product, develop and test the best visual style and user-test the prototype.</p>
		<p>In this way you will know that what will be created is the best possible solution.</p>
		</article>

		<article>
		<h3>Full-Stack Engineering</h3>
		<p>Building you product for web and/or mobile platforms iOS and Android.</p>
		<p>With all the latest and modern technologies you can think of to make sure that your product is the fastest, robust and bulletproof.</p>
		<p>We have expertise and capacity to take on a project of any complexity.</p>
		</article>

		<article>
		<h3>Digital Solutions for the Future</h3>
		<p>Our software developing house is built on top of Barcelona Code School where we build software and train designers and developers since 2015.</p>
		<p>All the experience and expertise we have plus infinite pool of available developers and designers allow us to assign a team for your project from the start and deliver in the shortest time with the best quality possible.</p>
		</article>


		<h2>How we work</h2>

		<article>
		<h3><span className='for2col'>1: </span>Meeting you</h3>
		<p>First you will have a thorough conversation with your Project Manager and the User Experience Designer. We will listen to you and ask a lot of questions to understand your product.</p> 
		<p> We will identify all the features you need, understand your target audience, the problem you are trying to solve and find out the visual references for the esthetics of your product.</p>
		</article>

		<article>
		<h3><span className='for2col'>2: </span>UX and UI Design</h3>
		<p>We will start working on the architecture and structure of your project. Conducting full UX research with discovery, definition, infomation architecture, sketching, testing, wireframing, prototyping and usability testing.</p>
		<p>We will test several styleguides and pick the one which was perceived as the best.</p>
		</article>


		<article>
		<h3><span className='for2col'>3: </span>Development phase</h3>
		<p>Once the architecture of your project and the visual styles are ready and confirmed with you our software engineers will start working on it ro bring it to live.</p>
		<p>With the most suitable and modern technologies we will see through the continious integration and delivery in phases starting from the core functionality so you can begin testing it and rolling out the full version in the shortest time possible.</p>
		<p>We can take care of deployment and publishing if needed completing the cycle to the moment of your project being live on web or AppStore/Google Play.</p>
		</article>

		<h2>Some products we've created</h2>

		<article>
		<h3><a href='https://toolboxai.app' target ='_blank'>toolboxAI.app</a></h3>
		<p>Set of AI tools on demand</p>
		<a href='https://toolboxai.app' target ='_blank'>
		<img style={styles.images} src="images/toolboxAI.jpg" alt="toolboxAI.app - set of AI tools on demand" />
		</a>
		</article>

		<article>
		<h3><a href='http://bsides.barcelona' target ='_blank'>BSides.barcelona</a></h3>
		<p>CyberSecurity forum's website</p>
		<a href='http://bsides.barcelona' target ='_blank'><img style={styles.images} src="images/bsides-barcelona.jpg" alt="BSides.barcelona – CyberSecurity forum's website" /></a>
		</article>

		<article>
		<h3><a href='https://goodbadplace.com' target ='_blank'>Good Bad Place</a></h3>
		<p>Rate your landlord</p>
		<a href='https://goodbadplace.com' target ='_blank'><img style={styles.images} src="images/goodbadplace.jpg" alt="Good Bad Place - landlords and rentals reviews platform" /></a>
		</article>

		<article>
		<h3><a href='https://barcelonacodeschool.com/' target ='_blank'>Barcelona Code School</a></h3>
		<p>Programming school's website</p>
		<a href='https://barcelonacodeschool.com/' target ='_blank'><img style={styles.images} src="images/bcs.jpg" alt="Barcelona Code School" /></a>
		</article>


		<article>
		<h3><a href='https://bxlcybersummit.be' target ='_blank'>Brussels CyberSecurity Summit</a></h3>
		<p>Participants landing page</p>
		<a href='https://bxlcybersummit.be' target ='_blank'><img style={styles.images} src="images/ccb.jpg" alt="Brussels CyberSecurity Summit" /></a>
		</article>


		<article>
		<h3><a href='https://apps.apple.com/us/app/weather-os/id6443399556' target ='_blank'>Weather OS</a></h3>
		<p>iOS weather app</p>
		<a href='https://apps.apple.com/us/app/weather-os/id6443399556' target ='_blank'><img style={styles.images} src="images/weatherOS.jpg" alt="Weather OS - a terminal themed weather app" /></a>
		</article>


		<article>
		<h3><a href='https://apps.apple.com/us/app/picky-eater-go/id6451353816?platform=iphone' target ='_blank'>Picky Eater Go!</a></h3>
		<p>iOS/Android arcade game</p>
		<a href='https://apps.apple.com/us/app/picky-eater-go/id6451353816?platform=iphone' target ='_blank'><img style={styles.images} src="images/pickyEaterGo.jpg" alt="Picky Eater Go! - iOS arcade game" /></a>
		</article>


		<article>
		<h3><a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target ='_blank'>Guess a Word or Die</a></h3>
		<p>iOS/Android word game</p>
		<a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target ='_blank'><img style={styles.images} src="images/guessaword.jpg" alt="Guess a Word or Die - iOS word game" /></a>
		</article>


		<article>
		<h3><a href='https://apps.apple.com/us/app/i-have-i-need/id1621110781' target ='_blank'>I Have, I Need</a></h3>
		<p>iOS/Android Inventory and recurring shopping list</p>
		<a href='https://apps.apple.com/us/app/i-have-i-need/id1621110781' target ='_blank'><img style={styles.images} src="images/ihaveineed.jpg" alt="I Have, I Need - Inventory and recurring shopping list" /></a>
		</article>

		<article>
		<h3><a href='https://apps.apple.com/us/app/anime-calculator/id1567378874?platform=iphone' target ='_blank'>Anime Calculator</a></h3>
		<p>Anime themed calculator app for iOS and Android</p>
		<a href='https://apps.apple.com/us/app/anime-calculator/id1567378874?platform=iphone' target ='_blank'><img style={styles.images} src="images/animecalc.jpg" alt="Anime themed calculator app for iOS and Android" /></a>
		</article>


		</main>



		<ContactForm/>



		<footer className='centered'>
		<p><a href='https://undefeatedsoftware.dev' >Undefeated Software</a> / <a href='https://barcelonacodeschool.com/' target='_blank'>Barcelona Code School</a> © {(new Date()).getFullYear()}</p>
		<address>c/ Paris 157, 08036, Barcelona, Spain</address>
		</footer>
		</>
		)
}