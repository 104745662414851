import {useState} from 'react'
import Grid from 'react-easy-css-grid-layout'
import {useMatchMedia} from 'react-hook-match-media'
import axios from 'axios'

const rArrow = 'images/icons/_i_icon_05984_icon_059842.svg'

export default function WhatWeDo() {

   

  let columns = '1'


  return (
    <section>
    <Grid columns={columns} gap={'1em'}>

    <div>
    <article className='uxuicontainer'>
    <img className='icon' src={rArrow}/><h3 className='servicesHeading'>User Experience Design</h3>
    <p>Before starting the development we can conduct the full process of User Experience (UX) and User Interface (UI) Design.</p>
    <p>From the problem you are trying to solve we can validate it, conduct the user research to understand that your idea will meet the users' needs, design the structure and architecture of your product, develop and test the best visual style and user-test the prototype.</p>
    <p>In this way you will know that what will be created is the best possible solution.</p>
    </article>

    <article>
    <img className='icon' src={rArrow}/><h3 className='servicesHeading'>Full-Stack Engineering</h3>
    <p>Building you product for web and/or mobile platforms iOS and Android.</p>
    <p>With all the latest and modern technologies you can think of to make sure that your product is the fastest, robust and bulletproof.</p>
    <p>We have expertise and capacity to take on a project of any complexity.</p>
    </article>
    </div>

    <article>
    <img className='icon' src={rArrow}/><h3 className='servicesHeading'>Digital Solutions for the Future</h3>
    <p>Our software developing house is built on top of Barcelona Code School where we build software and train designers and developers since 2015.</p>
    <p>All the experience and expertise we have plus infinite pool of available developers and designers allow us to assign a team for your project from the start and deliver in the shortest time with the best quality possible.</p>
    </article>

    </Grid>
    </section>
    )
}