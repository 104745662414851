import React, {useState} from 'react'
import V1 from './v1'
import V2 from './v2'
import V3 from './v3'

function App() {

  const [version,setVersion]=useState(3)



  return (
    <div className="App">
    {
      version === 1 ?
      <V1/> : 
      version === 2 ? 
      <V2/> : 
      version === 3 ? 
      <V3/> : null
    }
      </div>
      );
}

export default App;
